import React, { useState } from 'react'
import { Button } from '../Button/Button'
import TextTransition, { presets } from 'react-text-transition'
import "./HeroSection.css"
import "../../App.css"
function HeroSection() {
    const jobs = ["student", "web developer", "java enthusiast"]

    const [jobState, setJob] = useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() =>
            setJob(jobState => jobState + 1),
            2000
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className="hero-container">
            <h1 className='hero-text'>Hi! <br />
                I'm Ch4se,<br />
                <span className='hero-job'>
                    <TextTransition
                        text={jobs[jobState % jobs.length]}
                        springConfig={presets.wobbly}
                        noOverflow
                    />
                </span>
            </h1>
            <img className='hero-img' src='/images/Avatar.png' alt='awangImage'/>
            <div style={{clear:'both'}}></div>
            <div className="hero-btns">
                <Button className="contactButton" buttonStyle={"btn--outline"} buttonSize={"btn--large"} buttonLink={"/contactme"}>get in touch</Button>
            </div>
        </div>
    )
}

export default HeroSection
