import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Sidebar.css'
import { SidebarData } from './SidebarData'

function Sidebar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>

            <nav className={click ? 'sidebar active' : 'sidebar'}>
                <div className='sidebar-container'>
                    <Link to="/" className={click ? 'sidebar-logo active' : 'sidebar-logo'} onClick={closeMobileMenu}>
                        <div className='logo-container'>
                            <img className='logo-img' src="/images/Avatar.png" alt="awangLogo"></img>
                            <h1 className='logo-name'>Ch4se</h1>
                            <p className='logo-job'>Web Developer</p>
                        </div>
                    </Link>

                    <ul className={click ? 'sidebar-socials active' : 'sidebar-socials'}>
                        <li>
                            <a className='social-links' rel="noreferrer" href="https://github.com/alexwangcoquitlam" target="_blank">
                                <i class="fab fa-github" />
                            </a>
                        </li>
                        <li>
                            <a className='social-links' rel="noreferrer" href="https://www.linkedin.com/in/alexander-wang-364504126/" target="_blank">
                                <i class="fab fa-linkedin-in" />
                            </a>
                        </li>
                    </ul>

                    <ul className={click ? 'sidebar-list active' : 'sidebar-list'}>
                        {SidebarData.map((val, key) => {
                            return (
                                <li className='sidebar-row' key={key} onClick={() => { window.location.pathname = val.link }}>
                                    <div className='sidebar-row-icon'>{val.icon}</div>
                                    <div className='sidebar-row-text'>{val.title}</div>
                                </li>
                            )
                        })}
                    </ul>
                    <div className={click ? 'sidebar-footer active' : 'sidebar-footer'} style={{ width: "100%", height: "5%" }} />
                </div>
            </nav>
        </>
    )
}

export default Sidebar
