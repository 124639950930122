import "../../App.css"
import Footer from "../Footer/Footer";
import HeroSection from "../HeroSection/HeroSection"

function Home() {
    return (
        <>
            <HeroSection />
            <Footer/>
        </>
    );
}

export default Home;