import React from 'react'

export const SidebarData = [
    {
        title:"about",
        icon:<i class="fas fa-user"/>,
        link: "/"
    },
    {
        title:"projects",
        icon:<i class="fas fa-file-code"/>,
        link: "/projects"
    },
    {
        title:"experience",
        icon:<i class="fas fa-briefcase"/>,
        link: "/experience"
    },
    {
        title:"contact",
        icon:<i class="fas fa-envelope"/>,
        link: "/contactme"
    }
    
]
