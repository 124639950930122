import React from 'react'
import "./Footer.css"

function Footer() {
    return (
        <div className='footer-container'>
            <span></span>
        </div>
    )
}

export default Footer